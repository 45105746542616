import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import EbookHero from "../components/ebook-hero"
import SEO from "../components/seo"
import Container from "../components/container"
import DeskOrganizedImg from "../images/ebooks/desk-organized.png"
import theme from "../consts/theme"
import { getEbooks } from "../utils"

const SC = {}

const EbookEstudosMaisProdutivos = () => {
  const ebook = getEbooks("Organize & Estude melhor")

  return (
    <Layout>
      <SEO
        title={`[eBook] ${ebook.title}`}
        description={ebook.description}
        canonical={ebook.url.substring(1)}
        author="Apetrecho Digital"
      />
      <EbookHero
        cover={ebook.cover}
        title={`Aprenda a organizar
          <br />
          seus estudos de uma
          <br />
          vez por todas!`}
        description={`Baixe agora mesmo o eBook <b>"Organize &
        <br />
        Estude Melhor"</b> e tenha na palma da sua mão
        <br />
        um guia completo que vai ensinar você a <b>criar
        <br />
        o seu próprio método de organização</b> dos
        <br />
        estudos.`}
        titlePrimaryColor={theme.colors.PRIMARY_PURPLE}
        titleSecondaryColor={theme.colors.PRIMARY_PURPLE}
        descriptionColor="#000"
        leftBgColor={theme.colors.ESTUDOS}
        rightBgColor={theme.colors.PRIMARY_PURPLE}
        ebookTag={ebook.mailchimpTag}
        inputBgColor="#FFFFFF55"
      />
      <SC.Content
        isFlex
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
      >
        <SC.ContentTextWrapper>
          <SC.ContentTitle>Para quem é esse eBook?</SC.ContentTitle>
          <SC.ContentItems>
            <ul>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem nunca conseguiu seguir um cronograma de estudos
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>para quem sequer já teve um cronograma de estudos</span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem não faz ideia de como organizar seus materiais de
                  estudo
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem não consegue manter seus estudos organizados por
                  muito tempo
                </span>
              </li>
              <li>
                <FontAwesomeIcon
                  icon="check"
                  color={theme.colors.PRIMARY_GREEN}
                />
                <span>
                  para quem precisa de uma ajudinha com a organização dos
                  horários e materiais de estudo
                </span>
              </li>
            </ul>
          </SC.ContentItems>
        </SC.ContentTextWrapper>
        <SC.ContentImg src={DeskOrganizedImg} alt="Mesa organizada" />
      </SC.Content>
    </Layout>
  )
}

SC.Content = styled(Container)`
  padding-top: 40px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
  }
`

SC.ContentTitle = styled.span`
  color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  font-size: 32px;
  font-weight: 700;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    font-size: 26px;
    text-align: center;
  }
`

SC.ContentTextWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 0 80px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin-top: 40px;
    margin-bottom: 450px;
    padding: 0 25px;
  }
`

SC.ContentItems = styled.div`
  width: 70%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin-top: 40px;

    li {
      span {
        font-family: ${({ theme }) => theme.fonts.NUNITO};
        font-size: 22px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
          font-size: 18px;
        }
      }

      svg {
        margin-right: 5px;
        height: 14px;
      }
    }
  }
`

SC.ContentImg = styled.img`
  margin: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 400px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

export default EbookEstudosMaisProdutivos
